// @flow
import { getWhenCaseHistoryClosed } from 'guardian/admin/api/cases/CaseLocalStorage';
import { handleActions } from 'redux-actions';
import {
    SET_LAST_OPENED, REMOVE_LAST_OPENED,
} from './constants';

export type State = {
    lastOpened: {
        [string]: string,
    },
}

const initialState: State = {
    lastOpened: getWhenCaseHistoryClosed(),
};

const reducer = handleActions({
    [SET_LAST_OPENED]: (state: State, { payload }: {
        payload: {
            caseID: string,
            lastActivityTime: string,
        }
        // eslint-disable-next-line consistent-return
    }) => {
        const {
            caseID, lastActivityTime,
        } = payload;
        return {
            ...state,
            lastOpened: {
                ...state.lastOpened,
                [caseID]: lastActivityTime,
            },
        };
    },
    [REMOVE_LAST_OPENED]: (state: State, { payload }: {
        payload: {
            caseID: string,
        }
    }) => {
        const { caseID } = payload;
        const newState = { ...state };
        delete newState.lastOpened[caseID];
        return {
            ...newState,
        };
    },
}, initialState);

export default reducer;
