// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Redirect, Route, Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { Provider } from 'react-redux';
import {
    createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import * as Sentry from '@sentry/browser';
import 'whatwg-url';

import { IntlProvider } from 'react-intl';
import Cookie from 'js-cookie';
import providersEnMessages from 'locale/lang/providers/en.json';
import providersBgMessages from 'locale/lang/providers/bg.json';
import sharedEnMessages from 'locale/lang/shared/en.json';
import sharedBgMessages from 'locale/lang/shared/bg.json';

import {
    ApiClient, WebSocketClient, JWTBackend, AuthLocalStorage,
} from 'speed-js-core';
import ApiClientAuthBackend from 'speed-js-core/src/websocket/auth/ApiClientAuthBackend';
import {
    APIProvider, SchemaForm, WSContext,
    reducers as speedReducers,
} from 'speed-js-react';
import { setPermissions, setUser } from 'speed-js-react/src/redux/auth/actions';

import {
    REACTSTRACT_UI_DEFAULT_FIELDS, REACTSTRACT_UI_FALLBACK_FIELD_TYPE,
    reducers as tmplReducers,
} from 'speed-react-gogo-tmpl';

import { resources } from 'guardian/providers/api';
import reducers from 'guardian/providers/redux/reducers';

import 'speed-react-gogo-tmpl/src/assets/css/vendor/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-table/react-table.css';
import 'leaflet/dist/leaflet.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import 'speed-react-gogo-tmpl/src/assets/fonts/simple-line-icons/css/simple-line-icons.css';
import 'speed-react-gogo-tmpl/src/assets/fonts/iconsmind-s/css/iconsminds.css';
import 'speed-react-gogo-tmpl/src/assets/sass/theme.scss';

import * as serviceWorker from './serviceWorker';


if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Sentry.Integrations.GlobalHandlers({
                onerror: true,
                onunhandledrejection: false,
            }),
        ],
    });
}


// REGISTER FORM FIELDS
SchemaForm.registerFieldTypes(REACTSTRACT_UI_DEFAULT_FIELDS, REACTSTRACT_UI_FALLBACK_FIELD_TYPE);


// INIT REDUX
let composeEnhancers = compose;
const middlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
    middlewares.push(logger);
}

const store = createStore(
    combineReducers({
        ...speedReducers,
        ...tmplReducers,
        ...reducers,
    }),
    composeEnhancers(applyMiddleware(...middlewares)),
);


// INIT HISTORY
const history = createHistory({
    basename: process.env.REACT_APP_PROVIDERS_HISTORY_BASE_NAME || `${process.env.PUBLIC_URL}/providers`,
});

const locale = 'bg' || Cookie.get('locale');

// INIT API CLIENT
const apiClient = new ApiClient(
    process.env.REACT_APP_API_ROOT, resources, JWTBackend, {
        AuthStorageClass: AuthLocalStorage,
        authResourceName: 'auth',
    }, {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': locale,
    }, (client) => {
        // Set the initial user
        if (client.authorized) {
            client.auth.refreshTokens().then(() => {
                client.resources.auth.get().then((response) => {
                    store.dispatch(setUser(response));
                    store.dispatch(setPermissions(response));
                });
            });
        }
    },
);


// INIT WEBSOCKET CLIENT
const wsAuth = new ApiClientAuthBackend(apiClient);
const wsClient = new WebSocketClient(process.env.REACT_APP_WS_ENDPOINT, wsAuth, window.sessionStorage);

const enMessages = { ...providersEnMessages, ...sharedEnMessages };
const bgMessages = { ...providersBgMessages, ...sharedBgMessages };

const messages = { en: enMessages, bg: bgMessages };

const App = React.lazy(() => import('guardian/providers/App'));

ReactDOM.render((
    <Provider store={store}>
        <Router history={history}>
            <Route
                exact
                path="/"
                render={() => (
                    <Redirect to="/center" />
                )}
            />
            <APIProvider apiClient={apiClient}>
                <WSContext.Provider value={wsClient}>
                    <IntlProvider locale={locale} messages={messages[locale]}>
                        <React.Suspense fallback={<div className="loading" />}>
                            <App />
                        </React.Suspense>
                    </IntlProvider>
                </WSContext.Provider>
            </APIProvider>
        </Router>
    </Provider>
// $FlowFixMe
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
