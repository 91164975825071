// @flow

export const setWhenCaseHistoryClosed = (historyOpened: Object) => localStorage
    .setItem('lastOpened', JSON.stringify(historyOpened));

export const getWhenCaseHistoryClosed = () => {
    const id = localStorage.getItem('lastOpened');
    if (id) JSON.parse(id);

    return {};
};
